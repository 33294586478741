<template>
  <Header
    ref="headerRef"
    type="RESTRICTIONS_OF_CRYPTO_ASSETS"
    :title="t('menus.Reports')"
    :show-regenerate-button="false"
    :updated-on="updatedTime ? t('common.updatedOn') + utils.dateFormatting(updatedTime) + ' UTC' : ''"
  />
  <ReportsMenus
    ref="ReportsMenusRef"
    type="RESTRICTIONS_OF_CRYPTO_ASSETS"
    :title="t('project.nav.RestrictionsCryptoAssets')"
  />

  <GuideStep v-if="reportStore.entityDetail?.guideStep < 3" type="RESTRICTIONS_OF_CRYPTO_ASSETS" />

  <div v-else v-loading="loading" style="min-height: 65vh">
    <FinancialStatement
      financial-type="restrictions"
      :is-show-regenerated-info="ReportsMenusRef?.isShowRegeneratedInfo"
    />
  </div>
</template>

<script setup lang="ts">
import utils from '@/lib/utils'
import { useI18n } from 'vue-i18n'
import Header from '../components/Header.vue'
import GuideStep from '../components/GuideStep.vue'
import ReportsMenus from '../../components/ReportsMenus.vue'
import { useReportStore } from '@/stores/modules/reports/index'
import FinancialStatement from '../components/FinancialStatement.vue'

const { t } = useI18n()
const reportStore = useReportStore()
const { loading } = storeToRefs(reportStore)
const headerRef = ref()
const ReportsMenusRef = ref()

const updatedTime = computed(() => {
  return reportStore.financialData.updatedOn
})
</script>

<style lang="scss" scoped></style>
